<template>
    <div>
        <el-header active-text-color="">
            <div class="container top">
                <div v-if="isLogin" class="left-wrap hidden-sm-and-up">
                    <van-popover
                        v-model="isShowPopover"
                        placement="bottom-start"
                        trigger="click"
                    >
                        <template #reference>
                            <van-badge :dot="giftNoticeNum > 0">
                                <i class="el-icon-message-solid"></i>
                            </van-badge>
                        </template>
                        <div class="van-popover__content" role="menu">
                            <div v-for="item in actions"
                                 :key="item.id"
                                 class="van-popover__action van-popover__action--with-icon"
                                 role="menuitem"
                                 @click="handleRoute(item.path)"
                            >
                                <van-badge :content="giftNoticeNum>0?giftNoticeNum:''" class="d-flex">
                                    <i :class="['van-icon-' + item.icon]" class="van-icon van-popover__action-icon"></i>
                                    <div class="van-popover__action-text van-hairline--bottom">{{ item.text }}</div>
                                </van-badge>
                            </div>
                        </div>
                    </van-popover>
                </div>
                <h1>
                    <a href="/">
                        <img :src="logo | cdnurl" alt="" class="top-logo top-logo-big">
                        <img :src="logo | cdnurl" alt="" class="top-logo top-logo-small">
                    </a>
                </h1>
                <el-menu
                    v-if="isLogin"
                    :default-active="activeIndex"
                    active-text-color="#3B5998"
                    background-color="#fff"
                    class="nav"
                    mode="horizontal"
                    text-color="#595959"
                    @select="handleSelect"
                >
                    <el-menu-item class="nav-item f-w-m " index="/">
                        <i class="menu-icon icon-al-chazhao"></i>
                        {{ $t("search") }}
                    </el-menu-item>

                    <el-menu-item class="nav-item f-w-m " index="/message">
                        <i class="menu-icon icon-al-jiaoyou"></i>
                        {{ $t("message") }}
                        <sup v-if="unreadMessageCount > 0"
                             class="el-badge__content is-fixed not_read_num">{{ unreadMessageCount }}</sup>
                    </el-menu-item>
                    <el-menu-item class="nav-item f-w-m " index="/mailbox">
                        <i class="menu-icon icon-al-youjian_"></i>
                        {{ $t("Mailbox") }}
                        <sup v-if="unreadMailCount > 0"
                             class="el-badge__content is-fixed not_read_num">{{ unreadMailCount }}</sup>
                    </el-menu-item>
                    <el-menu-item v-if="systemVersion != 'F' || systemVersion!='G'" class="nav-item f-w-m "
                                  index="/hot">
                        <i class="menu-icon icon-al-xianxingduoseremen"></i>
                        {{ $t("DynamicSquare") }}
                    </el-menu-item>
                    <el-menu-item v-if="systemVersion == 'F' || systemVersion == 'G'" class="nav-item f-w-m "
                                  index="/voicechat">
                        <i class="menu-icon icon-al-xianxingduoseremen"></i>
                        Chart
                    </el-menu-item>
                    <el-menu-item class="nav-item f-w-m " index="/people">
                        <i class="menu-icon el-icon-s-custom"></i>
                        {{ $t("People") }}
                    </el-menu-item>
                    <el-submenu class="nav-member f-w-m" index="orders">
                        <template slot="title">
                            <i class="menu-icon el-icon-s-order"></i>
                            Orders
                        </template>
                        <el-menu-item class="nav-item f-w-m " index="/orders">
                            <i class="menu-icon el-icon-s-goods"></i>
                            {{ $t("GiftOrder") }}
                            <sup v-if="giftNoticeNum > 0"
                                 class="el-badge__content is-fixed not_read_num">{{ giftNoticeNum }}</sup>
                        </el-menu-item>
                        <el-menu-item class="nav-item f-w-m " index="/orders/phone">
                            <i class="menu-icon el-icon-phone"></i>
                            Phone Order
                            <sup v-if="phoneNoticeNum > 0"
                                 class="el-badge__content is-fixed not_read_num">{{ phoneNoticeNum }}</sup>
                        </el-menu-item>
                    </el-submenu>
                    <el-menu-item class="nav-item f-w-m " index="/recharge">
                        <i class="menu-icon icon-al-jinbi1"></i>
                        {{ $t("Recharge") }}
                    </el-menu-item>

                    <el-submenu class="nav-item nav-member" index="/user">
                        <template slot="title">
                            <el-avatar :size="30" :src="userinfo.avatar | cdnurl" class="headerAvatar"></el-avatar>
                        </template>
                        <el-menu-item index="/user">
                            <i class="menu-icon icon-al-gerenzhongxin"></i>
                            {{ $t('PersonalCenter') }}
                        </el-menu-item>
                        <!-- <el-submenu class="" index="">
                            <template slot="title">
                                <i class="menu-icon icon-al-duoyuyan"></i>
                                {{ $t("language") }}
                            </template>
                            <el-menu-item index="" class="nav-item-li" @click="changeLang('en')">
                                <i class="menu-icon icon-al-yuyan"></i>
                                English
                            </el-menu-item>
                            <el-menu-item index="" class="nav-item-li" @click="changeLang('zh-cn')">
                                <i class="menu-icon icon-al-yuyan1"></i>
                                中文
                            </el-menu-item>
                        </el-submenu> -->
                        <el-menu-item @click="logout">
                            <i class="menu-icon icon-al-tuichudenglu"></i>
                            {{ $t('logout') }}
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
                <h1 v-if="isLogin" class="nav-btn">
                    <i class="icon el-icon-menu hidden-xs-only" @click="drawer = true"></i>
                    <i class="icon el-icon-user-solid hidden-sm-and-up" @click="handleRoute('/mine')"></i>
                </h1>
            </div>
        </el-header>

        <el-drawer :visible.sync="drawer" direction="rtl" size="50%">
            <el-menu class="el-menu-vertical-demo" default-active="2" @select="handleSelect">
                <el-menu-item index="/">
                    <i class="el-icon-search"></i>
                    <span>{{ $t("search") }}</span>
                </el-menu-item>
                <el-menu-item index="/message">
                    <i class="el-icon-chat-dot-round"></i>
                    <span>{{ $t("message") }}</span>
                </el-menu-item>
                <el-menu-item index="/mailbox">
                    <i class="menu-icon icon-al-youjian_"></i>
                    {{ $t("Mailbox") }}
                    <sup v-if="unreadMailCount > 0" class="el-badge__content is-fixed not_read_num">{{
                            unreadMailCount
                        }}</sup>
                </el-menu-item>
                <el-menu-item index="/hot">
                    <i class="el-icon-sunny"></i>
                    <span>{{ $t("DynamicSquare") }}</span>
                </el-menu-item>
                <el-menu-item index="/people">
                    <i class="menu-icon el-icon-s-custom"></i>
                    {{ $t("People") }}
                </el-menu-item>
                <el-menu-item index="/orders">
                    <i class="menu-icon el-icon-s-goods"></i>
                    {{ $t("GiftOrder") }}
                </el-menu-item>
                <el-menu-item index="/recharge">
                    <i class="menu-icon icon-al-jinbi1"></i>
                    {{ $t("Recharge") }}
                </el-menu-item>
                <!-- <el-submenu class="" index="">
                    <template slot="title">
                        <i class="icon-al-duoyuyan"></i>
                        {{ $t("language") }}
                    </template>
                    <el-menu-item index="" class="nav-item-li" @click="changeLang('en')">
                        <i class="icon-al-yuyan"></i>
                        English
                    </el-menu-item>
                    <el-menu-item index="" class="nav-item-li" @click="changeLang('zh-cn')">
                        <i class="icon-al-yuyan1"></i>
                        中文
                    </el-menu-item>
                </el-submenu> -->

                <el-submenu class=" nav-member" index="member">
                    <template slot="title">
                        <el-avatar :size="30" :src="userinfo.avatar | cdnurl" class="headerAvatar"></el-avatar>
                    </template>
                    <el-menu-item index="/user">
                        <i class="icon-al-gerenzhongxin"></i>
                        {{ $t('PersonalCenter') }}
                    </el-menu-item>
                    <el-menu-item @click="logout">
                        <i class="icon-al-tuichudenglu"></i>
                        {{ $t('logout') }}
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </el-drawer>
    </div>
</template>
<script>
import Auth from '@/services/auth.js';
import {mapState, mapGetters, mapMutations} from 'vuex';
import {Popover, Badge} from 'vant';

export default {
    name: 'HeaderView',
    components: {
        VanBadge: Badge,
        VanPopover: Popover
    },
    data: function () {
        return {
            actions: [
                {id: 0, text: 'Gift Order', icon: 'gift-o', path: '/orders'},
                {id: 0, text: 'Phone Order', icon: 'phone-o', path: '/orders/phone'},
            ],
            activeIndex: '',
            drawer: false,
            keepAlive: false,
            logo: "/assets/img/logo.png",
            isShowPopover: false
        }
    },
    computed: {
        ...mapState([
            'isLogin',
            'giftNoticeNum',
            'phoneNoticeNum',
        ]),
        ...mapGetters({
            userinfo: 'getUserinfo',
            unreadMessageCount: 'getUnreadMessageCount',
            unreadMailCount: 'getUnreadMailCount',
            systemVersion: 'getSystemVersion',
        })
    },
    watch: {
        $route() {
            this.isShowPopover = false
        }
    },
    methods: {
        ...mapMutations([
            'setUserinfo',
            'setIsLogin',
        ]),
        handleRoute(action) {
            this.$router.push(action)
        },
        changeLang: function (lang) {
            this.lang = lang
            localStorage.setItem("language", lang);
            this.$i18n.locale = lang; //这个代码负责实时切换语言
        },
        logout: function () {
            let that = this;

            that.$http.post('/user/logout')
                .then((res) => {
                    if (res.code == 1) {
                        // 设置用户信息
                        that.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.setUserinfo({})
                        this.setIsLogin(false)

                    } else {
                        that.$message.error(res.msg);
                    }
                    Auth.delToken()
                    that.$router.push("/login")
                })


        },
        handleSelect(key, keyPath) {
            if (!key) {
                return
            }
            this.drawer = false
            this.$router.push(key)
        }
    },
    created() {
        this.activeIndex = this.$route.path
    },
}
</script>
<style lang="scss" scoped>
#app .el-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1500;
    border-bottom: 1px solid #e8e8e8;
    height: 60px !important;
}

.el-header {
    background-color: #fff;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-wrap {
        font-size: 22px;
        color: var(--primaryColor);
    }
}

.popover-wrap {
    width: 150px;

    .link-item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #ccc;
        transition: all .2s;

        &:active {
            background-color: var(--blue-100);
        }

        &:last-of-type {
            border-bottom: none;
        }

        .menu-icon {
            margin-right: 5px;
        }
    }
}

.van-popover__action {
    width: 150px;
}

.top h1 {
    margin: 0;
    float: left;
    font-size: 32px;
    font-weight: 400;
}

.top {
    max-width: 1300px;
    margin: 0 auto;
}

.top,
.top h1,
.top-logo {
    height: 100%;
}

.top .top-logo-small {
    display: none;
}

.nav {
    float: right;
    height: 59px !important;
    line-height: 59px;
    background: transparent;
    padding: 0;
    margin: 0;

}

.not_read_num {
    top: 20px !important;
    right: 30px !important;;
}

.nav-item,
.el-menu--horizontal > .el-submenu .el-submenu__title {
    height: 59px !important;
    line-height: 59px !important;
}

/* .nav-item .el-submenu__title i {
    color: #595959;
} */


.nav-btn {
    color: #3B5998;
    height: 60px;
    display: none;
    float: right !important;
}

.nav-btn i {
    margin-right: 5px;
    text-align: center;
    font-size: 22px;
    line-height: 60px;
    vertical-align: middle;
}

.nav-member .el-submenu__icon-arrow {
    display: none;
}

.nav-member .el-avatar {
    background: none;
}

.el-menu--popup {
    min-width: 100px !important;
}

.menu-icon {
    font-size: 22px;
}

@media (max-width: 767px) {
    .top .top-logo-big {
        display: none;
    }

    .top .top-logo-small {
        display: inline-block;
    }

    .nav {
        display: none;
    }

    .nav-btn {
        display: block;
    }
}
</style>