<template>
    <div ref="scrollRef">
        <div :gutter="1" class="card-header shadow">
            <div class="header-left">
                <h1 class="header-title" style="float: left;">
                    <i class="icon-al-chazhao" style="font-size: 24px;"></i>
                    Chat Room
                </h1>
            </div>
            <div class="header-right">
                <el-button type="primary" plain @click="handleShowCreateRoom">Create Room</el-button>
            </div>
        </div>

        <el-row :gutter="20" class="card-body">
            <template>
                <el-skeleton :count="12" :loading="loading" :throttle="200" animated>
                    <template slot="template">
                        <el-col :lg="6" :md="8" :sm="12" :xs="12" class="card-item m-no-padding">
                            <el-skeleton-item style="width: 100%;height: 240px;" variant="image" />
                            <div style="padding: 14px;text-align: center;">
                                <el-skeleton-item style="width: 50%;margin: 0 auto;" variant="h3" />
                                <div
                                    style="display: flex; align-items: center; justify-items: space-between; margin-top: 16px; height: 16px;">
                                    <el-skeleton-item style="margin-right: 16px;" variant="text" />
                                    <el-skeleton-item style="width: 30%;" variant="text" />
                                </div>
                            </div>
                        </el-col>
                    </template>
                </el-skeleton>
                <el-col v-for="(row, index) in list" :key="index" :lg="8" :md="8" :sm="12" :xs="12" class="card-item ">
                    <el-card :body-style="{ padding: '0px' }" class="card-row" shadow="hover">
                        <div class="room-wrapper" @click="handleEnterChartRoom(row)">
                            <div class="image-wrap">
                                <img :src="cdnurl(row.image)">
                            </div>
                            <div class="room-desc">
                                <div class="room-info">
                                    <span class="float-left card-nickname"> {{row.name}} </span>
                                </div>
                                <button type="button" class="el-button chatNowBtn el-button--warning el-button--small">
                                    <span>{{ userinfo.id === row.ownerId ? 'Enter' : 'Join in' }}</span>
                                </button>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </template>

            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pagination">
                <el-pagination :current-page="searchData.page" :hide-on-single-page="true" :page-size="searchData.limit"
                    :pager-count="isMobile ? 5 : 7" :total="total" background layout="prev, pager, next"
                    @current-change="changePage">
                </el-pagination>
            </el-col>

            <el-empty v-if="list.length == 0 && !loading" :description="$t('DataIsEmpty')"></el-empty>
        </el-row>

        <el-dialog 
            :close-on-click-modal="false"
            title="Create Room"
            :visible.sync="isShowCreateRoom"
        >
            <div>
                <el-form class="profileForm" :model="profileForm" :rules="rules" ref="profileForm" label-width="160px" label-position="right">
                    <el-row :gutter="20">
                        <el-col :xs="24">
                            <el-form-item label="cover" prop="image">
                                <cropper v-if="isShowCreateRoom" @changeAvatar="changeAvatar" :avatar="profileForm.image" :fixedNumber="[1, 1.3]"></cropper>
                            </el-form-item>
                        </el-col>

                        <el-col :xs="24">
                            <el-form-item label="name" prop="name">
                                <el-input v-model="profileForm.name" placeholder="Please enter a room name"></el-input>
                            </el-form-item>
                        </el-col>
                        

                        <el-col :xs="24">
                            <el-form-item label="duration" prop="duration">
                                <el-input v-model="profileForm.duration" placeholder="Please enter the duration">
                                    <template slot="append">min</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        
                        <el-col :xs="24">
                            <el-form-item>
                                <el-button type="primary" @click="submitForm()">{{ $t('submit') }}</el-button>
                                <el-button @click="resetForm('profileForm')">{{ $t('reset') }}</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { MessageBox } from 'element-ui'
import { Dialog } from 'vant'
import cropper from '@/page/components/user/cropper.vue';

import { mapState, mapGetters } from "vuex";

import { cdnurl } from '@/filters'

export default {
    name: 'ListView',
    components: {
        cropper,
    },
    data: function () {
        return {
            searchData: {
                page: 1,
                limit: 12,
            },
            list: [],
            total: 0,
            loading: true,
            searchShow: false,

            rules: {
                image: [
                    { required: true, message: 'Please upload the cover', trigger: 'blur' }
                ],
                name: [
                    { required: true, message: 'Please enter a room name', trigger: 'blur' },
                ],
                duration: [
                    { required: true, message: 'Please enter the duration', trigger: 'blur' },
                ], 
            },
            profileForm: {
                image: '',
                name: '',
                duration: '', // 秒
            },
            isShowCreateRoom: false,
        }
    },
    watch: {
        
    },
    computed: {
        ...mapState({
            isMobile: 'isMobile',
            userinfo: 'userinfo',
        }),
        ...mapGetters({
            isVIP: 'getIsVIP',
        }),
    },
    created() {
        this.getList();
    },
    methods: {
        handleEnterChartRoom(room) {
            if (room.status === 2) {
                return this.$message.warning('The room is no longer functional')
            }
            if (this.isVIP) {
                const href = this.$router.resolve({
                    path: '/voicechatroom',
                    query: {
                        roomnum: room.id,
                    }
                }).href
                window.open(href, '_blank')
            } else {
                if (this.isMobile) {
                    Dialog.confirm({
                        title: '',
                        message: '<i class="van-icon van-icon-warning" style="font-size:20px;color:var(--theme-primary);transform:translate(-7px, 3px);"></i>Only after completing the information can the operation be carried out!',
                    })
                        .then(() => {
                            this.$router.push({
                                path: '/benefits',
                                query: {type: 'vip'}
                            })
                        })
                        .catch(() => {
                            // on cancel
                        });
                } else {
                    MessageBox.confirm('Expire of vip, whether to jump to the recharge page?', '', {
                        confirmButtonText: 'confirm',
                        cancelButtonText: 'cancel',
                        type: 'warning'
                    }).then(() => {
                        this.$router.push({
                            path: '/benefits',
                            query: {type: 'vip'}
                        })
                    }).catch(() => {

                    });
                }
            }
        },
        handleShowCreateRoom() {
            this.isShowCreateRoom = true
        },
        changeAvatar(data) {
            this.profileForm.image = data.avatar
        },
        submitForm() {
            const profileForm = this.$refs['profileForm']
            profileForm.validate((valid) => {
                if (valid) {
                    this.$http.post('/room/create-voice-room', {...this.profileForm, duration: this.profileForm.duration * 60})
                        .then((res) => {
                            if (res.code == 1) {
                                this.isShowCreateRoom = false
                                profileForm.resetFields()
                                this.getList()
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'success'
                                });
                                
                            } else {
                                this.$message({
                                    showClose: true,
                                    message: res.msg,
                                    type: 'error'
                                });
                            }

                        })
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.profileForm.image = false
        },
        createRoom() {
            this.$http.get('/room/create-voice-room', { params: this.searchData })
                .then((res) => {
                    if (res.code == 1) {
                        
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        search: function () {
            this.searchData.page = 1;
            this.list = [];
            this.getList();
            this.searchShow = false;
        },
        getList: function () {
            let that = this;
            this.$http.get('/room/get-voice-room', { params: this.searchData })
                .then((res) => {
                    if (res.code == 1) {
                        this.list = res.data.list || [];
                        this.total = res.data.total;
                        this.loading = false
                        that.$scrollTo()
                    } else {
                        this.$message.error(res.msg);
                    }

                })
        },
        changePage: function (page) {
            this.searchData.page = page;
            this.getList();
            // 回到顶部
            this.$refs.scrollRef.scrollTop = 0;
        },
        jump: function (id) {
            let that = this;
            that.$router.push({ path: `memberinfo/${id}` })
        }
    },
}
</script>

<style lang="scss" scoped>
.card-header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    border: 1px solid #ebebeb;
    border-bottom: none;
    border-radius: 3px 3px 0 0;
    transition: .2s;

    .header-left {
        flex: 1;
    }

    .header-title {
        color: #3B5998;
    }
}

.card-body {
    width: 100%;
    border: 1px solid #ebebeb;

    border-radius: 0 0 3px 3px;
    transition: .2s;
    padding: 15px;


    .card-item {
        margin-bottom: 20px;

        .card-row {
            border-radius: 10px !important;
            position: relative;
        }
    }
}

.room-wrapper {

    .image-wrap {
        aspect-ratio: 1 / 1.3;

        img {
            display: block;
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all .3s;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

    .room-desc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .room-wrapper {

        .room-desc {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px;

            .room-info {
                width: 100%;
                margin-bottom: 10px;

                .card-nickname {
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
</style>
