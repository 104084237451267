<template>
    <div ref="scrollRef">
        <div :gutter="1" class="card-header shadow">
            <div class="header-left">
                <h1 class="header-title" style="float: left;">
                    <i class="icon-al-chazhao" style="font-size: 24px;"></i>
                    Chat Room
                </h1>
            </div>
        </div>

        <div class="card-body">
            <ConferenceMainView></ConferenceMainView>
        </div>

    </div>
</template>
<script>
import { mapState } from 'vuex';

import { MessageBox } from 'element-ui'
import { Dialog } from 'vant'
import { ConferenceMainView, conference, RoomEvent, TUIRoomEngine } from '@/components/TUIRoom/index.ts'
import { cdnurl } from '@/filters';

export default {
    name: 'ListView',
    components: {
        ConferenceMainView,
    },
    data: function () {
        return {
            sdkAppId: '',
            userSig: '',
            roomnum: '',
        }
    },
    watch: {
        userinfo: function (newVal) {
            this.startConference(newVal)
        }
    },
    computed: {
        ...mapState({
            isMobile: 'isMobile',
            userinfo: 'userinfo',
        }),
    },
    created() {
        this.roomnum = this.$route.query.roomnum

        // 离开会议
        conference.on(RoomEvent.ROOM_LEAVE, () => {
            window.close()
        })
        // 会议解散
        conference.on(RoomEvent.ROOM_DISMISS, () => {
            if (this.isMobile) {
                    Dialog.alert({
                        title: '',
                        message: 'The room is disbanded',
                    })
                        .then(() => {
                            window.close()
                        })
                } else {
                    MessageBox.alert('The room is disbanded', '', {
                        confirmButtonText: 'confirm',
                        type: 'warning'
                    }).then(() => {
                        window.close()
                    })
                }
        })
        // 用户登出
        conference.on(RoomEvent.USER_LOGOUT, () => {
            window.close()
        })
    },
    beforeDestroy() {
        conference.leave();
    },
    methods: {
        
        startConference(userInfo) {
            this.$http.post('/room/get-params', { user_id: userInfo.id}).then(async res => {
                await conference.login({
                    sdkAppId: +res.data.params.sdkAppId,
                    userId: userInfo.id + '',
                    userSig: res.data.params.userSig, 
                });
                    
                await conference.join(this.roomnum, {
                    roomName: 'chart room',
                    isOpenMicrophone: true,
                })
                
                conference.setSelfInfo({
                    userName: userInfo.nickname,
                    avatarUrl: cdnurl(userInfo.avatar)
                });

                TUIRoomEngine.once('ready', async () => {
                    const roomEngine = conference.getRoomEngine();
                    await roomEngine.changeUserNameCard({
                        userId: userInfo.id + '',
                        nameCard: userInfo.nickname,
                        avatarUrl: cdnurl(userInfo.avatar),
                    });
                });
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.card-header {
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    border: 1px solid #ebebeb;
    border-bottom: none;
    border-radius: 3px 3px 0 0;
    transition: .2s;

    .header-left {
        flex: 1;
    }

    .header-title {
        color: #3B5998;
    }
}

.card-body {
    box-sizing: border-box;
    width: 100%;
    padding: 15px;
    border: 1px solid #ebebeb;
    border-radius: 0 0 3px 3px;
    transition: .2s;

}

</style>