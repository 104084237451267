export default {
    // apiUrl: 'http://test2.jingkela.com/api',
    // cdnUrl: 'http://test1.jingkela.com',
    // wsUrl: 'ws://test2.jingkela.com/api/websocket/chat',


    // apiUrl: 'https://api.permingle.com/api', // 线上地址
    // cdnUrl: 'https://console.permingle.com', // 线上地址
    // wsUrl: 'wss://api.permingle.com/api/websocket/chat', // 线上地址


    // apiUrl: 'https://api.matchmeetion.com/api', // 线上地址
    // cdnUrl: 'https://console.matchmeetion.com', // 线上地址
    // wsUrl: 'wss://api.matchmeetion.com/api/websocket/chat', // 线上地址


    // apiUrl: 'https://api.permeeting.com/api', // 线上地址
    // cdnUrl: 'https://console.permeeting.com', // 线上地址
    // wsUrl: 'wss://api.permeeting.com/api/websocket/chat', // 线上地址

    // apiUrl: 'https://api.perfeeling.com/api', // 线上地址
    // cdnUrl: 'https://console.perfeeling.com', // 线上地址
    // wsUrl: 'wss://api.perfeeling.com/api/websocket/chat', // 线上地址


    // apiUrl: 'https://api.justformate.com/api', // 线上地址
    // cdnUrl: 'https://console.justformate.com', // 线上地址
    // wsUrl: 'wss://api.justformate.com/api/websocket/chat', // 线上地址


    apiUrl: 'https://api.perloving.com/api', // 线上地址
    cdnUrl: 'https://console.perloving.com', // 线上地址
    wsUrl: 'wss://api.perloving.com/api/websocket/chat', // 线上地址


    // apiUrl: 'http://127.0.0.1:8198/api', // 线上地址
    // cdnUrl: 'http://127.0.0.1', // 线上地址
    // wsUrl: 'ws://127.0.0.1:8198/api/websocket/chat', // 线上地址


    userToken: 'token',
    initConfig: 'initconfig',
}
